// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-articles-modern-intranet-jsx": () => import("./../../../src/pages/articles/modern-intranet.jsx" /* webpackChunkName: "component---src-pages-articles-modern-intranet-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-solutions-customer-platforms-jsx": () => import("./../../../src/pages/solutions/customer-platforms.jsx" /* webpackChunkName: "component---src-pages-solutions-customer-platforms-jsx" */),
  "component---src-pages-solutions-integration-platforms-jsx": () => import("./../../../src/pages/solutions/integration-platforms.jsx" /* webpackChunkName: "component---src-pages-solutions-integration-platforms-jsx" */),
  "component---src-pages-solutions-intranets-jsx": () => import("./../../../src/pages/solutions/intranets.jsx" /* webpackChunkName: "component---src-pages-solutions-intranets-jsx" */),
  "component---src-pages-solutions-partner-portals-jsx": () => import("./../../../src/pages/solutions/partner-portals.jsx" /* webpackChunkName: "component---src-pages-solutions-partner-portals-jsx" */)
}

